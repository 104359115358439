import React, { useState, useEffect } from "react";
import SweeprLayouts from "../fields/SweeprLayouts.json"
import SweeprBox from "./SweeprBox";
import SweeprControls from "../parts/SweeprControls";

function SweeprField(props) {
    const mines = SweeprLayouts[props.currentDate].small;
    var rowAmm, colAmm;
    switch (props.fieldSize) {
        case 'small':
            rowAmm = 8;
            colAmm = 8;
            break;
        case 'medium':
            rowAmm = 16;
            colAmm = 16;
            break;
        case 'large':
            rowAmm = 16;
            colAmm = 30;
            break;
        default:
        case 'mini':
            rowAmm = 8;
            colAmm = 5;
            break;
    }

    const [selectionType, setSelectionType] = useState("safe");
    const [boxes, setBoxes] = useState(buildField());

    // Functions for building the field
    function buildField(resetBoxes = false) {
        // Check for saved field
        if (
            !props.boxes ||
            resetBoxes
        ) {
            let newBoxes = [];
            // Build new field
            for (var y = 0; y < rowAmm; y++) {
                var cols = [];
                for (var x = 0; x < colAmm; x++) {
                    cols.push({
                        x: x,
                        y: y,
                        boxValue: "",
                        key: `box_${x}_${y}`,
                        checkNext: false
                    });
                }
                newBoxes.push(cols);
            }
            return newBoxes;
        } else {
            // Load field
            return props.boxes;
        }
    }
    // Controls
    function changeSelectionType(e) {
        setSelectionType(e.target.value);
    }
    function boxClicked(x, y) {
        // Check for mine
        var newBoxes = boxes;
        newBoxes[y][x].checkNext = false;

        switch (selectionType) {
            case "mine":
                newBoxes[y][x].boxValue = "mine";
                break;
            case "unsure":
                newBoxes[y][x].boxValue = "unsure";
                break;
            case "safe":
                // Check for mine
                if (checkIfMine(x, y)) {
                    // BOOM
                    console.info("Booooom");
                    newBoxes = buildField(true);
                    props.updateGameState("loss")
                } else {
                    // Check surroundings
                    var mineNr = checkMineNr(x, y);
                    newBoxes[y][x].boxValue = mineNr;
                    // Check for 0
                    if (mineNr === 0) {
                        // Activate all neighbours
                        checkNext(x, y - 1, newBoxes); // N
                        checkNext(x + 1, y - 1, newBoxes); // NE
                        checkNext(x + 1, y, newBoxes); // E
                        checkNext(x + 1, y + 1, newBoxes); // SE
                        checkNext(x, y + 1, newBoxes); // S
                        checkNext(x - 1, y + 1, newBoxes); // SW
                        checkNext(x - 1, y, newBoxes); // W
                        checkNext(x - 1, y - 1, newBoxes); // NW                
                    }
                }
                break;
            default:
                console.log('Something went wrong');
                break;
        }
        setBoxes([...newBoxes]);
    }
    // Checks and helpers
    function checkNext(x, y, newBoxes) {
        if (x >= 0 && x < colAmm && y >= 0 && y < rowAmm && newBoxes[y][x].boxValue === "") {
            newBoxes[y][x].checkNext = true;
        }
    }
    function checkIfMine(x, y) {
        var isMine = false;
        mines.forEach(function (item) {
            if (item.x === x && item.y === y) {
                isMine = true;
            }
        });
        return isMine;
    }
    function checkMineNr(x, y) {
        var mineNr = 0;
        if (checkIfMine(x, y - 1)) mineNr++; // N
        if (checkIfMine(x + 1, y - 1)) mineNr++; // NE
        if (checkIfMine(x + 1, y)) mineNr++; // E
        if (checkIfMine(x + 1, y + 1)) mineNr++; // SE
        if (checkIfMine(x, y + 1)) mineNr++; // S
        if (checkIfMine(x - 1, y + 1)) mineNr++; // SW
        if (checkIfMine(x - 1, y)) mineNr++; // W
        if (checkIfMine(x - 1, y - 1)) mineNr++; // NW
        return mineNr;
    }

    useEffect(() => {
        props.updateBoxes(boxes);
        const boxAmm = rowAmm * colAmm;
        let foundAmm = 0;
        for (var y = 0; y < rowAmm; y++) {
            for (var x = 0; x < colAmm; x++) {
                if (
                    boxes[y][x].boxValue !== '' &&
                    boxes[y][x].boxValue !== 'mine' &&
                    boxes[y][x].boxValue !== 'unsure'
                ) foundAmm++;
            }
        }
        if (foundAmm === boxAmm - mines.length) props.updateGameState("win");

    }, [boxes, props, colAmm, rowAmm, mines]);

    return (
        <div className="sweeprField" >
            <SweeprControls
                changeSelectionType={changeSelectionType}
                selectionType={selectionType}
            />
            <div className="play_area">
                <div className="overflow_controller">
                    {boxes.map((row, y) => (
                        <div
                            className="sweepr_row"
                            key={`row_${y}`}
                        >
                            {row.map((box, x) => (
                                <SweeprBox
                                    x={x}
                                    y={y}
                                    boxValue={box.boxValue}
                                    boxClicked={boxClicked}
                                    checkNext={box.checkNext}
                                    key={`box_${x}_${y}`}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default SweeprField;