import React, { useEffect, useState } from "react";
import SweeprField from "../parts/SweeprField";
import SweeprModal from "../parts/SweeprModal";
import "../scss/Sweepr.scss";

function ScreenSweepr() {
    const [gameTries, setGameTries] = useState(() => {
        let loadedGameTries = parseInt(localStorage.getItem(`sweepr_gameTries_small`));
        if (isCurrentSave() && loadedGameTries) {
            return loadedGameTries;
        } else {
            return 1;
        }
    });
    const [gameTime, setGameTime] = useState(() => {
        let gameTimeData = parseInt(localStorage.getItem(`sweepr_gameTime_small`));
        if (isCurrentSave() && gameTimeData) {
            return gameTimeData;
        } else {
            return 0;
        }
    });
    const [gameState, setGameState] = useState(() => {
        let gameStateData = localStorage.getItem(`sweepr_gameState_small`);
        if (isCurrentSave() && gameStateData) {
            return gameStateData;
        } else {
            return "paused";
        }
    });
    const [gameBoxes, setGameBoxes] = useState(() => {
        let gameBoxesData = localStorage.getItem(`sweepr_gameBoxes_small`);
        if (isCurrentSave() && gameBoxesData) {
            return JSON.parse(gameBoxesData);
        } else {
            return null;
        }
    });

    // Saving related
    function getCurrentDate() {
        let currentDate = new Date(Date.now());
        currentDate.setMilliseconds(0);
        currentDate.setSeconds(0);
        currentDate.setMinutes(0);
        currentDate.setHours(0);
        currentDate = currentDate.getTime();
        return currentDate;
    }
    function isCurrentSave() {
        let loadedGameDate = parseInt(localStorage.getItem(`sweepr_gameDate_small`));
        if (loadedGameDate && loadedGameDate === getCurrentDate()) {
            return true;
        } else {
            return false;
        }
    }

    function updateBoxes(newBoxes) {
        setGameBoxes(newBoxes);
    }
    function updateGameState(newGameState) {
        switch (newGameState) {
            case "win":
                // if (gameState === "playing") {
                //     statsData.dayStreak++;
                //     statsData.deathlessStreak++;
                // }
                setGameState("win");
                break;
            case "loss":
                setGameTries(gameTries + 1);
                // statsData.deathlessStreak = 0;
                setGameState("loss");
                setGameBoxes(null);
                break;
            case "playing":
                if (gameState === "loss") {
                    updateBoxes(null);
                }
                if (gameState === "paused" || gameState === "loss") setGameTime(gameTime + 1);
                setGameState("playing");
                break;
            case "paused":
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        localStorage.setItem(`sweepr_gameDate_small`, getCurrentDate());
    }, []);
    useEffect(() => {
        localStorage.setItem(`sweepr_gameTries_small`, gameTries);
    }, [gameTries]); // Save game data
    useEffect(() => {
        localStorage.setItem(`sweepr_gameState_small`, gameState);
    }, [gameState]); // Save game data
    useEffect(() => {
        localStorage.setItem(`sweepr_gameBoxes_small`, JSON.stringify(gameBoxes));
    }, [gameBoxes]); // Save game data
    useEffect(() => {
        localStorage.setItem('sweepr_gameTime_small', gameTime);
        let lastTime = Date.now();
        if (gameState === "playing") {
            setTimeout(() => {
                let currentTime = Date.now();
                setGameTime(gameTime + (currentTime - lastTime));
            }, 250);
        }
    }, [gameTime, gameState]); // Update gametime

    useEffect(() => {
        // console.log("Render") 
    });

    return (
        <div className="sweepr">
            <div className="sweepr_stats">
                <div className="gametime">
                    Time<br />
                    <b>{String(Math.floor(gameTime / 60000)).padStart(2, '0')}:{String(Math.floor((gameTime / 1000) % 60)).padStart(2, "0")}</b>
                </div>
                <div className="gametries">
                    Tries<br />
                    <b>{gameTries}</b>
                </div>
            </div>

            <SweeprField
                fieldSize="small"
                boxes={gameBoxes}
                currentDate={getCurrentDate()}
                updateGameState={updateGameState}
                updateBoxes={updateBoxes}
                gameState={gameState}
                gameTime={gameTime}
                gameTries={gameTries}
            />
            <SweeprModal
                gameState={gameState}
                gameTime={gameTime}
                gameTries={gameTries}
                updateGameState={updateGameState}
            />
        </div>
    );
}

export default ScreenSweepr;