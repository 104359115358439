import React from "react";

function SweeprControls(props) {
    function checkSelected(selectionCheckBox) {
        if (selectionCheckBox === props.selectionType) return "selected";
    }
    return (
        <div className="sweeprControls">
            <fieldset className="clickTypeSelect">
                <legend>Select selection:</legend>
                <label
                    className={checkSelected("safe")}
                >
                    <input
                        type="radio"
                        value="safe"
                        checked={props.selectionType === "safe"}
                        onChange={props.changeSelectionType}
                    />
                    Safe
                </label>
                <label
                    className={checkSelected("unsure")}
                >
                    <input
                        type="radio"
                        value="unsure"
                        checked={props.selectionType === "unsure"}
                        onChange={props.changeSelectionType}
                    />
                    Unsure
                </label>
                <label
                    className={checkSelected("mine")}
                >
                    <input
                        type="radio"
                        value="mine"
                        checked={props.selectionType === "mine"}
                        onChange={props.changeSelectionType}
                    />
                    Mine
                </label>
            </fieldset>
        </div>
    );
}

export default SweeprControls;