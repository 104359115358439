import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

function SweeprBox(props) {
    var classNames = ["sweepr_box_inside"];

    function handleClick() {
        props.boxClicked(props.x, props.y);
    }

    function getBoxClasses() {
        switch (props.boxValue) {
            case "mine":
                classNames.push("flag_box");
                break;
            case "unsure":
                classNames.push("unsure_box");
                break;
            default:
                classNames.push(`box_${props.boxValue}`);
                break;
        }
    }


    function getBoxVisual() {
        switch (props.boxValue) {
            case "mine":
                return (<FontAwesomeIcon icon={solid('flag')} />);
            case "unsure":
                return (<FontAwesomeIcon icon={solid('question')} />);
            default:
                return (<span>{props.boxValue}</span>);
        }
    }

    useEffect(() => {
        if (props.checkNext == true && props.boxValue === '') {
            props.boxClicked(props.x, props.y);
        }
    });

    getBoxClasses();
    return (
        <div

            className="sweepr_box"
            onClick={handleClick}
        >
            <div className={classNames.join(" ")}>
                {getBoxVisual()}
            </div>
        </div>
    );
}

export default SweeprBox;