import React, { useEffect } from "react";

const MediaNetAd = ({ id, size }) => {
    useEffect(() => {
        if (typeof window !== "undefined") {
            try {
                window._mNHandle.queue.push(function () {
                    window._mNDetails.loadTag(id, size, id);
                });
            } catch (error) { }
        }
    }, [id, size]);

    return (
        <div className="mediaAdWrapper">
            <div id={id} />
        </div>
    );
};

export default MediaNetAd;
