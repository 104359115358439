import React from "react";
import { RWebShare } from "react-web-share";
import MediaNetAd from "./MediaNetAdd";

function SweeprModal(props) {
    function resetLoss() {
        props.updateGameState("playing");
    }
    function startGame() {
        props.updateGameState("playing");
    }
    function setPaused() {
        props.updateGameState("paused");
    }
    function getTimeVisual() {
        return (
            <span>{String(Math.floor(props.gameTime / 60000)).padStart(2, '0')}:{String(Math.floor((props.gameTime / 1000) % 60)).padStart(2, "0")}
            </span>
        );
    }
    function getTimeText() {
        return (String(Math.floor(props.gameTime / 60000)).padStart(2, '0') + ":" + String(Math.floor((props.gameTime / 1000) % 60)).padStart(2, "0"));
    }
    function getShareVisual() {
        let textToShare = `I swept the field in ${getTimeText()} using only ${props.gameTries} ${props.gameTries === 1 ? "try" : "tries"}! Can you beat me at Sweepr?`;
        return (
            <div>
                <RWebShare
                    data={{
                        text: textToShare,
                        url: "https://sweepr.nl",
                        title: "Sweepr daily challenge",
                    }}
                    onClick={() => console.log("shared successfully!")}
                >
                    <button>
                        Share your achievement!
                    </button>
                </RWebShare>
            </div>
        );
    }
    function getContents() {
        switch (props.gameState) {
            case "paused":
                return (
                    <div>
                        <h1>Welcome to Sweepr!</h1>
                        <p>Try our daily challenge to bring back that old Mine&nbsp;Sweeper nostalgia!</p>
                        <button onClick={startGame}>
                            Start Sweepn!
                        </button>
                    </div>
                );
            case "loss":
                return (
                    <div>
                        <h1>BOOOOM</h1>
                        <p>Ouch! That appears to have been a mine... You can try again if you like.</p>
                        Time: {getTimeVisual()}<br />
                        Tries: {props.gameTries - 1}<br />
                        <button onClick={resetLoss}>
                            try again
                        </button>
                    </div>
                );
            case "win":
                return (
                    <div>
                        <h1>You're awesome!</h1>
                        <p>You cleared the field of all the mines in {getTimeVisual()} using only {props.gameTries} {props.gameTries === 1 ? "try" : "tries"}!</p>
                        {getShareVisual()}
                        <p>And don't forget to come back tomorrow for a new challenge!</p>
                    </div>
                );
            default:
                setPaused();
                break;
        }
    }

    return (
        <div className={`sweepr_modal state_${props.gameState}`} >
            <div className="modal_contents">
                {getContents()}
                <MediaNetAd id="306381251" size="300x250" />
            </div>
        </div >
    );
}

export default SweeprModal;