import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ScreenSweepr from "./screens/ScreenSweepr";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ScreenSweepr fieldSize="small" />} />
        <Route path="beginner" element={<ScreenSweepr fieldSize="small" />} />
        <Route path="intermediate" element={<ScreenSweepr fieldSize="medium" />} />
        <Route path="expert" element={<ScreenSweepr fieldSize="large" />} />
        <Route path="mini" element={<ScreenSweepr fieldSize="mini" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
